<template>
    <div class="library" v-if="modelValue">
        <ul class="tabs">
            <li class="active" @click="handleMainFolder()">{{ isArchived ? 'Archive Folders' : 'Main Folders'}}</li>
            <li v-for="(item, i) in folderBreadcrumb" :key="i" @click="handleBreadcrumb(item, i)">{{ `${item.title}` }}</li>
            <li class="archive_right" @click="toggleArchiveFolder(!isArchived)">{{ isArchived ? 'Main Folders' : 'Archive Folders'}}</li>
        </ul>
        <ul class="folders_list" @mousewheel="scrollFunc($event)">
            <li>
                <div class="folder_item" @click="handleAddAsset(folderParams.parent_id)" v-if="!isArchived && folderBreadcrumb.length > 0">
                    <img src="@/assets/images/add.svg" alt="">
                    Add {{ assetType }}
                </div>
                <div class="folder_item" @click="handleCreateFolder" v-if="!isArchived">
                    <img src="@/assets/images/add.svg" alt="">
                    Add New Folder
                </div>
            </li>
            <draggable v-model="folderList" item-key="id" class="flex" :animation="200" @change="handleSortFolder">
                <template #item="{ element, index }">
                    <li>
                        <div class="folder_item" @click="selectFolder(element, index + 1)">
                            <img src="@/assets/images/folder3.svg">
                            <span
                            class="editable"
                            :id="`editable-${index + 1}`"
                            :class="{ 'content_editable': isContenteditable == index + 1 }"
                            :contenteditable="isContenteditable == index + 1 ? true : false"
                            @blur="handleContenteditable(index + 1)"
                            @input="handleUpdateFolder($event, element)">
                            {{ element.title }}
                            </span>
                            <div class="dropdown" :class="{ 'active': folderOption == index + 1 }" @click.stop.prevent="folderOption = folderOption == index + 1 ? 0 : index + 1" >
                                <i class="fas fa-ellipsis-v"></i>
                                <div class="drp_wrapper">
                                    <ul>
                                        <li @click="handleRenamefolder(element, index + 1)" v-if="element.archived == 0">Rename</li>
                                        <li @click="handleAddAsset(element.id)" v-if="element.archived == 0">Add {{ assetType }}</li>
                                        <li @click="handleArchiveFolder(element.id, 1)" v-if="element.archived == 0">Archive</li>
                                        <li @click="handleArchiveFolder(element.id, 0)" v-if="element.archived == 1">Restore</li>
                                        <li @click="handleDeleteFolder(element.id)" v-if="element.archived == 1">Delete</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>
            </draggable>
        </ul>
        <add-asset v-model="addAsset" :assetType="assetType" :title="title" :folder-id="folderId" :refresh-asset="refreshAsset" />
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import AddAsset from '@/components/AddAsset'
    import Draggable from 'vuedraggable'

    import Toastr from '@/utils/Toastr'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Asset Folder',

        data () {
            return {
                folderOption: 0,
                folderBreadcrumb: [],
                params: {
                    archived:  0,
                    parent_id: 0,
                    asset_type: ''
                },
                isContenteditable: 0,
                addAsset: false,
                folderId: null,
                isCreateFolder: false,
                folderList: [],
            }
        },

        props: {
            modelValue: Boolean,
            assetType: String,
            title: String,
            folderParams: Object,
            refreshFolder: Function,
            assetFolderWatcher: Number,
            isLastBreadcrumb: {
                type: Boolean,
                default: false,
            },
            updateBreadcrumb: {
                type: Function,
                default: () => {},
            },
            sectionBreadcrumb: {
                type: Array,
                default: () => [],
            }
        },

        emit: ['update:modelValue'],

        components: {
            AddAsset,
            Draggable
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                    vm.setIsAssetFolder(true);
                } else {
                    vm.setIsAssetFolder(false);

                    if (vm.isArchived) {
                        vm.params.archived  = 0;
                        vm.params.parent_id = 0;

                        vm.setIsArchivedStatus(false);
                        vm.handleFolderList();
                    }
                }
            },

            folderParams (value) {
                const vm = this;

                vm.resetForm();
            },

            folders (value) {
                const vm = this;

                vm.folderList = JSON.parse(JSON.stringify(value));

                if (value.length && vm.isCreateFolder) {
                    vm.isContenteditable = vm.folders.length;
                    vm.isCreateFolder    = false;

                    setTimeout(function () {
                        const folder      = document.getElementById(`editable-${vm.folders.length}`);
                        const range       = document.createRange();
                        const selectPoint = window.getSelection();

                        range.setStart(folder.childNodes[0], folder.childNodes[0].length);
                        selectPoint.removeAllRanges();
                        selectPoint.addRange(range);
                    }, 1000);
                }
            },

            assetFolderWatcher (value) {
                const vm = this;

                vm.getFolder(vm.params);
            },

            isContenteditable (val) {
                setTimeout(function () {
                    const vm = this;

                    if (!vm.isCreateFolder) {
                            const folder      = document.getElementById(`editable-${val}`);
                            const range       = document.createRange();
                            const selectPoint = window.getSelection();

                            if (folder) {
                                range.setStart(folder.childNodes[0], folder.childNodes[0].length);
                            }

                            selectPoint.removeAllRanges();
                            selectPoint.addRange(range);
                    }
                }, 1000);
            },

            folderBreadcrumb: {
                handler (breadcrumb) {
                    const vm = this;

                    vm.setFolderBreadcrumb({ key: vm.assetType, value: JSON.parse(JSON.stringify(breadcrumb)) });
                    vm.setLastFolderBreadcrumb(JSON.parse(JSON.stringify(breadcrumb)));
                    // vm.updateBreadcrumb(JSON.parse(JSON.stringify(breadcrumb)));
                },
                deep: true,
            }
        },

        computed: mapState({
            folders: state => state.folderModule.folders,
            isArchived: state => state.folderModule.isArchived,
            lastFolderBreadcrumb: state => state.folderModule.lastFolderBreadcrumb,
            isRefresh: state => state.folderModule.isRefresh,
        }),

        mounted () {
            const vm = this;

            if (vm.isLastBreadcrumb || vm.sectionBreadcrumb.length) {
                vm.folderBreadcrumb  = vm.isRefresh ? JSON.parse(JSON.stringify(vm.lastFolderBreadcrumb)) : JSON.parse(JSON.stringify(vm.sectionBreadcrumb));

                if (vm.sectionBreadcrumb.length) {
                    vm.$emit('update:modelValue', true);
                }
            } else {
                vm.folderBreadcrumb  = [];
            }

            setTimeout(function () {
                if (vm.folderParams.archived) {
                    vm.setIsArchivedStatus(true);
                    vm.$emit('update:modelValue', true);
                } else {
                    vm.setIsArchivedStatus(false);
                }

                vm.params.archived   = vm.folderParams.archived;
                vm.params.parent_id  = vm.folderParams.parent_id;
                vm.params.asset_type = vm.assetType;
                vm.isContenteditable = 0;

                vm.getFolder(vm.params);
            }, 100);
        },

        methods: {
            ...mapActions({
                getFolder: 'folderModule/getFolder',
                createFolder: 'folderModule/createFolder',
                updateFolder: 'folderModule/updateFolder',
                archiveFolder: 'folderModule/archiveFolder',
                deleteFolder: 'folderModule/deleteFolder',
                sortFolders: 'folderModule/sortFolders',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setLastFolderBreadcrumb: 'folderModule/SET_LAST_FOLDER_BREADCRUMB',
                setFolderId: 'folderModule/SET_FOLDER_ID',
                setIsAssetFolder: 'folderModule/SET_IS_ASSET_FOLDER_STATUS',
                setFolderBreadcrumb: 'folderModule/SET_FOLDER_BREADCRUMB',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.params.archived   = vm.folderParams.archived;
                vm.params.parent_id  = vm.folderParams.parent_id;
                vm.params.asset_type = vm.assetType;
                vm.isContenteditable = 0;

                setTimeout(function () {
                    if (vm.folderParams.archived) {
                        vm.setIsArchivedStatus(true);
                        vm.$emit('update:modelValue', true);
                    } else {
                        vm.setIsArchivedStatus(false);
                    }

                    vm.getFolder(vm.params);
                }, 100);

                if (vm.isLastBreadcrumb || vm.sectionBreadcrumb.length) {
                    vm.folderBreadcrumb  = vm.isRefresh ? JSON.parse(JSON.stringify(vm.lastFolderBreadcrumb)) : JSON.parse(JSON.stringify(vm.sectionBreadcrumb));

                    if (vm.sectionBreadcrumb.length) {
                        vm.$emit('update:modelValue', true);
                    }
                } else {
                    vm.folderBreadcrumb  = [];
                }

                vm.handleFolderList();
            },

            handleFolderList () {
                const vm = this;

                vm.setFolderId(vm.params.parent_id);
                vm.getFolder(vm.params);
                vm.refreshFolder(vm.params);
            },

            selectFolder (folder, index) {
                const vm = this;

                if (vm.isContenteditable !== index) {
                    vm.params.parent_id               = folder.id;
                    vm.folderOption                   = 0;

                    if (vm.$parent.$parent && vm.$parent.$parent.folderParams) {
                        vm.$parent.$parent.folderParams.parent_id = folder.id;
                    } else {
                        vm.$parent.folderParams.parent_id = folder.id;
                    }

                    vm.folderBreadcrumb.push(folder);

                    vm.handleFolderList();
                }
            },

            handleMainFolder() {
                const vm = this;

                if (!vm.folderBreadcrumb.length) {
                    return void(0);
                }

                vm.params.archived  = vm.isArchived ? 1 : 0;
                vm.params.parent_id = 0;

                vm.folderBreadcrumb = [];

                vm.handleFolderList();
            },

            handleBreadcrumb (folder, index) {
                const vm = this;

                let length = vm.folderBreadcrumb.length;

                vm.folderBreadcrumb.splice(index + 1, length);

                vm.params.parent_id  = folder.id;

                vm.handleFolderList();
            },

            handleCreateFolder () {
                const vm = this;

                let form = { asset_type: vm.params.asset_type, parent_id: vm.params.parent_id }

                vm.createFolder(form).then((result) => {
                    if (result) {
                        vm.isCreateFolder = true;
                        vm.getFolder(vm.params);
                    }
                });
            },

            handleRenamefolder (folder, key) {
                const vm = this;

                vm.isContenteditable = key;
            },

            handleContenteditable() {
                const vm = this;

                vm.isContenteditable = 0;
            },

            handleUpdateFolder(e, folder) {
                const vm = this;

                if (event.target.innerText.length != 0 && event.target.innerText.length < 25) {
                    const params = {
                            id: folder.id,
                            title: event.target.innerText,
                            asset_type: folder.asset_type,
                    };

                    vm.updateFolder(params).then((result) => {
                        if (result == true) {
                            return;
                        } else if (result.response.status == 422) {
                            Toastr.error('The folder name has already been taken!');
                        } else {
                            Toastr.handleServerError(result);
                        }
                    });
                }
            },

            handleAddAsset (id) {
                const vm = this;

                vm.folderId = id;
                vm.addAsset = true;
            },

            refreshAsset () {
                const vm = this;

                vm.refreshFolder(vm.params);
            },

            handleArchiveFolder (id, archived) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', `You want to ${archived == 0 ? 'restore' : 'archived'} this folder and all its sub folder.`, 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        const params = { id: id, archived: archived };
                        vm.archiveFolder(params).then((result) => {
                            vm.handleFolderList();
                        });
                    }
                });
            },

            handleDeleteFolder (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'You want to archived this folder and all its sub folder.', 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        const params = { id: id, delete: 1 };
                        vm.deleteFolder(params).then((result) => {
                            vm.handleFolderList();
                        });
                    }
                });
            },

            toggleArchiveFolder (value) {
                const vm = this;

                vm.params.archived  = value ? 1 : 0;
                vm.params.parent_id = 0;
                vm.folderBreadcrumb = [];

                vm.setIsArchivedStatus(value);
                vm.handleFolderList();
            },

            handleSortFolder (e) {
                const vm  = this;
                const ids = [];

                vm.folderList.forEach(({ id }) => {
                    ids.push(id);
                });

                vm.sortFolders(ids);
            },
            scrollFunc(e){
                e.preventDefault();
                e.currentTarget.scrollLeft += e.deltaY;
            },
        }
    }
</script>

<style scoped>
    .folders_list {
        display: flex;
        justify-content: flex-start;
        /* flex-wrap: wrap; */
        padding: 20px 0;
        margin: 0 -5px 0 -5px;
        overflow-x: scroll;
    }
    .folders_list::-webkit-scrollbar{
        height: 10px;
    }
    .folders_list::-webkit-scrollbar-thumb{
        border-radius: 5px;
        background-color: #dbdbdb;
    }

    .folders_list li {
        padding: 0 5px;
        display: flex;
        /* margin-bottom: 10px; */
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 30px 10px 10px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 11px;
        line-height: 14px;
        color: #5A5A5A;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
        margin-right: 5px;
        position: relative;
        width: max-content;
    }

    .folders_list .folder_item img {
        max-height: 16px;
        width: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }
    .folders_list .folder_item .dropdown .drp_wrapper:before{
        top: 24px;
    }

    .folders_list .folder_item .dropdown {
        position: absolute;
        right: 10px;
        top: 5px;
    }
    .folders_list .folder_item .dropdown i{
        font-size: 12px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
        top: -23px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .content_editable {
        background: #2f7eed;
        color: #fff;
    }

    .archive_right {
        position: absolute;
        right: 10px;
        color: #666666;
    }
</style>
