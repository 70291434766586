<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ title }}<span>Select {{ title}}</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="loader_area2" v-show="loader"><quote-loader /></div>
            <div class="modal_body"  v-show="!loader">
                <div class="setting_wpr">
                    <ul class="sorting_card">
                        <li class="move" v-for="(asset, a) in assets" :key="a">
                            <img v-if="asset.dashboard_thumb || asset.thumbnail" :src="asset.dashboard_thumb ? asset.dashboard_thumb : asset.thumbnail">
                            <img v-else src="@/assets/images/thumb/default-playbook.svg">
                            <div class="card_details">{{asset.title}}</div>
                            <label :for="`add-${a}`" class="checkbox ml-auto px-3">
                                <input type="checkbox" :id="`add-${a}`" :value="asset.id" v-model="params.assets_id" hidden>
                                <span><i class="fas fa-check"></i></span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="addAssetLoader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="addAssetLoader" type="button" class="btn save_btn" @click="handleAddAsset"><i class="fa fa-spinner fa-spin" v-if="addAssetLoader"></i> {{ addAssetLoader ? 'Adding' : 'Add' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Add Asset',

        data () {
            return {
                params: {
                    assets_id: [],
                    asset_type: '',
                    folder_id: null,
                }
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            assetType: String,
            folderId: Number,
            refreshAsset: Function,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {

                    vm.params.assets_id  = [];
                    vm.params.asset_type = vm.assetType;
                    vm.params.folder_id  = vm.folderId;

                    setTimeout(() => {
                        vm.getSortedAssets({ asset_type: vm.params.asset_type })
                    }, 1000);

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

            },
        },

        computed: mapState({
            assets: state => state.commonModule.sortedAssets,
            loader: state => state.commonModule.loader,
            addAssetLoader: state => state.folderModule.addAssetLoader,
        }),

        methods: {
            ...mapActions({
                getSortedAssets: 'commonModule/getSortedAssets',
                addAssets: 'folderModule/addAssets'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddAsset () {
                const vm = this;

                vm.addAssets(vm.params).then((result) => {
                    if (result) {
                        vm.refreshAsset()
                        vm.closeModal();
                    }
                });
            },
        }
    }
</script>

<style>
    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 100px;
        height: auto;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13);
    }
</style>
